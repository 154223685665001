<template>
<div class="service-box left clearfix">
    <Header></Header>
    <!--顶部banner开始-->
    <div class="aboutus-banner left" style="margin-top:7px;height:660px;"> <img src="~images/zaixianfuwu.png" width="100%" /> </div>

    <div class="service-content clearfix left">
            <div class="service-contents clearfix">
                <!--面包屑开始-->
                <Breadcrumb>
                <a href="/#/index" title="首页"> <BreadcrumbItem>首页</BreadcrumbItem> </a>
                <BreadcrumbItem>会员服务</BreadcrumbItem>
                </Breadcrumb>

                <div style="height:20px;"></div>
                <div class="service-zixun zaixian left clearfix width-bai">
                    <div class="service-zixun-li left clearfix">
                       <ul>
                            <a href="/#/project_debriefing" title="项目进展查询"> <li style="margin-left:150px;"></li> </a>

                            <a href="/#/maintenance_label" title="工程后期维护"> <li style="margin-left:70px;"></li> </a>

                            <a href="/#/new_projects" title="新项目咨询"> <li style="margin-left:70px;"></li> </a>
                       </ul>
                   </div> 
                </div>
            </div>
            <div class="service-zixun zixun left clearfix width-bai"> </div>
        </div>

        <div class="left clearfix width-bai"> <Footer></Footer> </div>
        <contact-call></contact-call>
</div>
</template>
<script>
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
import ContactCall from '../index/ContactCall.vue'
export default {
   name:"",
   data() {
      return {
      }
   },
   components:{
       Header,
       Footer,
       ContactCall
   }
}
</script>

<style scoped>
    .service-zixun-li ul li{float:left;width:300px;height:330px;margin-left:17px;margin-left:100px;}
    .zaixian{background:url('~images/zaixianzixun.png') no-repeat center center;height:700px;}
    .service-zixun-li{width:100%;height:330px;margin-top:300px;}
    .zixun{background:url('~images/guanzhu.jpg') no-repeat center center;height:550px;}
    .service-contents{width:1440px;min-height:700px;margin:auto;}
    .service-box{width:100%;min-height:700px;}
    .service-content{width:100%;margin:auto;min-height:800px;}
</style>
